@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;700;800;900&display=swap");

$primary-color: #d22c2e;
$font-primary: "Roboto", sans-serif;
$font-secondary: "Raleway", sans-serif;

body {
  padding: 0;
  margin: 0;
  font-family: $font-primary;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button:focus {
  outline: none !important;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.login-wrapper {
  background-color: $primary-color;
  height: 100vh;
  position: relative;

  .inner-login-wrap {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: 0px 15px;
  }

  h1 {
    font-weight: 300;
    color: #fff;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  label {
    font-size: 16px;
    color: #fff;
  }

  .form-control {
    text-align: center;
    height: 55px;
    border-radius: 5px;
    border: 0;
  }

  button {
    background-color: #29a744;
    padding: 15px 0px;
    color: #fff;
    font-weight: 700;
    margin-top: 15px;
    width: 100%;
  }

  .otp-line {
    font-size: 14px;
    color: #d3d2d2;
    margin-bottom: 0;
    margin-top: 20px;
  }

  .step-two-wrap {
    .member-id-show {
      background-color: #fff;
      height: 55px;
      border-radius: 5px;
    }

    ul {
      margin-bottom: 0;
      line-height: 55px;

      li {
        display: inline-block;
        color: #000;
        font-weight: 700;
        font-size: 18px;
        margin-right: 25px;
      }

      li:last-child {
        margin-right: 0;
      }
    }
  }
}

// ==============================ABOUT US================================

.about-wrapper {
  .top-red-bar {
    background-color: $primary-color;
    padding: 25px 0 !important;
    text-align: center;

    h1 {
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0px;

      img {
        position: absolute;
        left: 15px;
        width: 22px;
        top: 3px;
      }
    }

    .red-logo {
      margin-top: 30px;
    }
  }

  .inner-wrapper {
    padding: 20px 0px;

    h2 {
      color: #122d52;
      text-align: center;
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    h6 {
      color: #122c52;
      font-size: 18px;
      text-align: center;
    }

    hr {
      border-top: 1px solid #c7c7c7;
    }
  }

  .content-wrapper {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 16px !important;
      text-align: left !important;
      font-weight: normal !important;
    }
  }
}

// ==================================END HERE=============================

// ===================================FEEDBACK============================

.feedback-wrapper {
  min-height: 100vh;
  position: relative;
  background-color: #e5ecf2;

  .top-red-bar {
    background-color: $primary-color;
    padding: 25px 0;
    text-align: center;

    h1 {
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0px;

      img {
        position: absolute;
        left: 15px;
        width: 22px;
        top: 3px;
      }
    }
  }

  .inner-wrapper {
    padding: 20px 0;

    h6 {
      text-align: center;
      color: #101211;
      line-height: 24px;
      margin-bottom: 20px;
    }

    .white-box-wrap {
      background-color: #fff;
      border-radius: 6px;
      padding: 20px 20px;
      -webkit-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      -moz-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);

      .form-control {
        border: 0;
        border-bottom: 2px solid #9099a8;
        border-radius: 0;
        padding: 0;
      }

      label {
        color: #5c6572;
      }

      textarea {
        resize: none;
      }
    }
  }

  button {
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $primary-color;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    padding: 15px 15px;
    border: 0;
  }
}

// =====================================END HERE=================================

// =====================================CONTACT US===============================

.contact-wrapper {
  background-color: #e5ecf2;

  .top-red-bar {
    background-color: $primary-color;
    padding: 25px 0;
    text-align: center;

    h1 {
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0px;

      img {
        position: absolute;
        left: 15px;
        width: 22px;
        top: 3px;
      }
    }
  }

  .inner-wrapper {
    padding: 20px 0;

    .red-logo {
      width: 110px;
      margin-bottom: 10px;
    }

    h6 {
      text-align: center;
      color: #7a818b;
      font-weight: 400;
      text-align: left;
      margin-bottom: 0;
    }

    .white-box-wrap {
      background-color: #fff;
      border-radius: 6px;
      padding: 20px 20px;
      -webkit-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      -moz-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);

      p {
        text-align: left;
        margin-bottom: 0;

        a {
          color: #112d52;
          text-align: left;
          margin-bottom: 0;
        }
      }
    }

    h5 {
      color: #717e8e;
      font-size: 16px;
      text-align: center;
      margin-bottom: 12px;
    }

    hr {
      border-bottom: 1px solid #cccccc;
      margin: 10px 0px;
    }

    ul {
      text-align: left;
      margin-bottom: 0;

      li {
        margin-bottom: 5px;
        display: flex;
        font-size: 15px;

        a {
          color: #112d52;
          font-weight: 600;
        }

        .icon {
          color: #d74343;
          font-size: 20px;
        }

        span {
          width: 27px;
          display: inline-block;
        }
      }
    }

    h3 {
      color: $primary-color;
      text-align: left;
      font-size: 20px;
      margin-bottom: 4px;
    }

    h4 {
      color: #245b93;
      text-align: left;
      font-size: 18px;
    }
  }
}

// =======================================END HERE=====================================

// ===================================== Dashboard ===================================

.dashboard-wrapper {
  background-color: #e4ebf1;
  min-height: 100vh;

  .dashboard-top {
    padding: 20px 0 70px 0;
    background-color: $primary-color;

    h1 {
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0px;
    }

    ul {
      li {
        display: inline-block;
        vertical-align: middle;

        .sm-logo {
          width: 50px;
          margin-left: 10px;
        }

        .humb-icon {
          width: 25px;
        }

        .date-icon {
          width: 25px;
        }

        .noti-icon {
          font-size: 30px;
          color: #fff;
          margin-left: 10px;
        }
      }

      .first-li {
        width: 30%;
      }

      .second-li {
        width: 40%;
      }

      .third-li {
        width: 30%;
      }
    }

    .new-notification {
      position: relative;
      display: inline-block;
      margin-right: 10px;

      span {
        background-color: #fff;
        width: 20px;
        height: 20px;
        display: inline-block;
        text-align: center;
        line-height: 17px;
        border-radius: 50px;
        color: $primary-color;
        font-weight: 700;
        font-size: 10px;
        position: absolute;
        right: -8px;
        top: 4px;
        border: 2px solid $primary-color;
      }
    }
  }

  .date-title-wrap {
    position: relative;
    margin-top: -65px;
    background-color: #fff;
    border-radius: 6px;

    .top-info {
      background-color: #eef5ff;
      border-radius: 6px 6px 0 0;
      text-align: center;
      padding: 12px 15px;

      h2 {
        font-size: 14px;
        color: #132d52;
        margin-bottom: 5px;
      }

      h3 {
        font-size: 16px;
        color: #132d52;
        margin-bottom: 0;
        font-weight: 700;
      }
    }

    .mid-info {
      padding: 10px 5px 15px 5px;
      -webkit-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      -moz-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);

      h6 {
        color: #58697b;
        font-size: 13px;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      p {
        color: #112d53;
        font-size: 16px;
        margin-bottom: 0;
        font-weight: 700;
      }

      .red-text {
        color: $primary-color;
      }

      .view-all {
        margin-top: 12px;

        a {
          font-size: 13px;
          color: #112d53;
          text-transform: uppercase;
        }

        .right-arrow {
          margin-left: 5px;
          position: relative;
          top: -1px;
          font-size: 13px;
          font-weight: 700;
        }
      }

      .border-div {
        border-left: 1px solid #aebfcc;
        border-right: 1px solid #aebfcc;
      }
    }
  }

  .news-event-wrap {
    margin-top: 15px;

    .inner-news-wrapper {
      background-color: #fff;
      -webkit-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      -moz-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      border-radius: 6px;
    }

    .wrap-4 {
      background-color: #0e7dc4;
      padding: 10px 10px;
      height: 100%;
      border-radius: 6px 0px 0px 6px;
      position: relative;
      text-align: center;

      a {
        position: absolute;
        bottom: 10px;
        text-align: center;
        left: 0;
        right: 0;
        font-size: 12px;
        color: #fff;
        text-decoration: underline;
      }

      h3 {
        margin-bottom: 0;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
      }
    }
    .news-event-row{
      min-height: 80px;
    }
    .event-slider {
      padding: 10px 0 30px 0px;

      h6 {
        color: #191919;
        font-size: 15px;
      }

      p {
        color: #2886c7;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 0;

        img {
          margin-right: 9px;
          width: 15px;
          display: inline-block;
          position: relative;
          top: -2px;
        }
      }
    }
  }

  .service-wrapper {
    margin-top: 15px;

    .row {
      margin: 0px -5px;
    }

    .service-box {
      background-color: #fff;
      border-radius: 6px;
      text-align: center;
      height: 125px;
      margin-bottom: 10px;
      padding: 20px 10px;
      -webkit-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      -moz-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);

      img {
        margin-bottom: 15px;
        width: 40px;
      }

      h6 {
        margin-bottom: 0;
        color: #0e1d49;
        font-weight: 700;
        font-size: 15px;
      }
    }

    .col-sm-4 {
      padding: 0 5px 0px 5px;
    }
  }

  .ad-banner {
    margin-top: 5px;
    padding-bottom: 15px;

    img {
      -webkit-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      -moz-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
      box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
    }
  }
}

// ======================================= END HERE===================================

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #0d7dc2;
}

.slick-dots li {
  margin: 0;
  width: 15px;
  height: 15px;
}

// ======================================== SIDEBAR WRAPPER==============================

.sidebar-wrapper {
  height: 100vh;
  background-color: #fff;
  width: 100%;
  position: fixed;
  z-index: 99;
  transform: translateX(-100%);
  transition: 0.5s all;

  .top-sidebar {
    background-color: $primary-color;
    padding: 45px 15px 15px 15px;
    text-align: center;

    h1 {
      font-size: 16px;
      color: #fff;
      font-weight: 400;
    }

    h2 {
      color: #fff;
      font-size: 18px;
      // text-decoration: underline;
    }

    .close-sidebar {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 35px;
      color: #fff;
      font-weight: 400;
    }
  }

  .menu-side-wrapper {
    ul {
      li {
        display: block;
        border-bottom: 1px solid #d2d2d2;

        a {
          display: block;
          color: #0a0a0a;
          font-size: 16px;
          padding: 20px 15px;
          font-weight: 600;
        }
      }

      li:last-child {
        border: 0;
      }
    }
  }

  .copywrite-wrapper {
    p {
      margin-bottom: 0;
      text-align: center;
      font-size: 12px;
      color: #848484;
      position: absolute;
      left: 0;
      bottom: 15px;
      right: 0;
    }
  }
}

.sidebar-active {
  transform: translateX(0%) !important;
}

// ========================================END HERE====================================

// ===================================COMMUNITY WRAPPER===============================

.community-wrapper {
  background-color: #e5ecf2;
  min-height: 100vh;

  .top-red-bar {
    background-color: $primary-color;
    padding: 25px 0;
    text-align: center;

    h1 {
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0px;

      img {
        position: absolute;
        left: 15px;
        width: 22px;
        top: 3px;
      }
    }
  }

  .inner-wrapper {
    padding: 20px 10px;
  }

  .extra-padding {
    padding: 20px 20px;
  }

  .no-padding-lr {
    padding: 20px 0px 0px !important;
  }

  .box-wrapper {
    background-color: #fff;
    border-radius: 6px;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px 10px 10px 10px;
    -webkit-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
    -moz-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
    box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
    display: flex;
    flex-direction: column;
    height: 100%;

    h3 {
      color: #0c1d48;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 5px;
    }

    img {
      margin-bottom: 20px;
      width: 50px;
    }

    a {
      color: $primary-color;
      font-size: 14px;
      font-weight: 700;
      display: inline-block;
    }
  }

  .col-sm-6 {
    padding: 0 5px 0px 5px;
    margin-bottom: 10px;
  }

  .detail-info {
    background-color: #fff;
    -webkit-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
    -moz-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
    box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
    padding: 20px 20px;
    border-radius: 6px;

    h2 {
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
    }

    a {
      color: $primary-color;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
      margin-top: 10px;
      display: inline-block;
    }
  }

  h6 {
    text-align: center;
    font-size: 16px;
    color: #000000;
    margin-top: 30px;
  }

  .requirement-wrap {
    .form-control {
      border: 0;
      border-bottom: 2px solid #9099a8;
      border-radius: 0;
      padding: 0;
    }

    label {
      margin-bottom: 0;
    }

    textarea {
      resize: none;
    }
  }

  button {
    color: #fff;
    width: 100%;
    background-color: $primary-color;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    padding: 15px 15px;
    border: 0;
    margin-top: 25px;
    margin-bottom: 0;
    border-radius: 6px;
  }
}

// =========================================END HERE==================================

// =================================== PERSONAL PROFILE==============================

.profile-wrapper {
  background-color: #e2e9ef;
  min-height: 100vh;

  .top-red-bar {
    background-color: $primary-color;
    padding: 20px 0 100px 0;
    text-align: center;

    h1 {
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0px;

      img {
        position: absolute;
        left: 15px;
        width: 22px;
        top: 3px;
      }
    }
  }

  .profile-info {
    background-color: #fff;
    -webkit-box-shadow: 3px 10px 5px -3px rgba(222, 222, 222, 0.74);
    -moz-box-shadow: 3px 10px 5px -3px rgba(222, 222, 222, 0.74);
    box-shadow: 3px 10px 5px -3px rgba(222, 222, 222, 0.74);
    padding: 20px 20px;
    border-radius: 6px;
    margin-top: -46px;
    position: relative;

    .profile-photo {
      width: 110px;
      height: 110px;
      border-radius: 6px;
      overflow: hidden;
      border: 2px solid #fff;
      background-color: #ddd;
      margin: 0px auto;
      margin-top: -60px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h6 {
      color: $primary-color;
      text-transform: uppercase;
      text-align: center;
      margin-top: 5px;
      font-size: 13px;
    }
  }

  .contact-details {
    background-color: #fff;
    -webkit-box-shadow: 5px 10px 5px -3px rgba(222, 222, 222, 0.74);
    -moz-box-shadow: 5px 10px 5px -3px rgba(222, 222, 222, 0.74);
    box-shadow: 5px 10px 5px -3px rgba(222, 222, 222, 0.74);
    padding: 20px 20px;
    border-radius: 6px;
    margin-top: 15px;
  }

  .form-control {
    border: 0;
    border-bottom: 1px solid #9099a8;
    border-radius: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 600;
    resize: none;
  }

  label {
    color: #55636e;
    font-weight: 300;
    font-size: 13px;
    margin-bottom: 0;
  }

  .profile-save {
    width: 100%;
    color: #fff;
    background-color: $primary-color;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    padding: 15px 15px;
    border: 0;
    margin-top: 25px;
    margin-bottom: 0;
  }
}

.select-wrap {
  position: relative;

  .caretDown {
    color: $primary-color;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
  }
}

.ant-select-selector {
  border: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ant-select .ant-select-arrow {
  right: 0 !important;
  display: none;
}

Select:focus {
  box-shadow: none !important;
  outline: none !important;
}

.ant-select-selection-item {
  font-weight: 600;
}

// =========================================END HERE==================================

// =====================================SAMAJ DARSHAN==============================

.darshan-wrapper {
  background-color: #fff;
  min-height: 100vh;

  .top-red-bar {
    background-color: $primary-color;
    padding: 20px 0 55px 0;
    text-align: center;

    h1 {
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0px;

      img {
        position: absolute;
        left: 15px;
        width: 22px;
        top: 3px;
      }
    }
  }

  .serachbar-wrapper {
    position: relative;
    margin-top: -30px;
    -webkit-box-shadow: 5px 10px 5px -5px rgba(222, 222, 222, 0.74);
    -moz-box-shadow: 5px 10px 5px -5px rgba(222, 222, 222, 0.74);
    box-shadow: 5px 10px 5px -5px rgba(222, 222, 222, 0.74);

    input {
      height: 60px;
      border-radius: 6px;
      border: 0;
      font-size: 16px;
    }

    .search-icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      font-size: 20px;
      color: $primary-color;
      padding: 0;
      border: 0;
      background-color: transparent;
    }
  }

  h6 {
    color: #9d9d9d;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 20px;
  }

  .list-of-darshan {
    margin-top: 5px;

    .list-wrapper {
      display: flex;
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: 15px;
      padding-top: 15px;
      align-items: center;

      .samaj-thumbnail {
        width: 65px;
        height: 65px;
        border-radius: 50px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .samaj-list-details {
        width: 80%;
        padding-left: 12px;

        h4 {
          color: #11224d;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 3px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        h5 {
          font-size: 14px;
          color: #25345b;
          font-weight: 400;
          margin-bottom: 0;
        }
      }
    }
  }
}

// =====================================END HERE====================================

// =====================================DARSHAN DETAILS============================

.darshan-detail-wrapper {
  .top-bar {
    a {
      position: absolute;
      left: 15px;
      width: 22px;
      top: 15px;
      z-index: 999;
    }
  }

  .top-red-bar {
    background-color: $primary-color;
    padding: 25px 0;
    text-align: center;

    h1 {
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0px;

      img {
        position: absolute;
        left: 15px;
        width: 22px;
        top: 3px;
      }
    }
  }

  .slick-dots {
    bottom: 25px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: $primary-color;
  }

  .slick-dots li button:before {
    font-size: 13px;
  }

  .slick-dots li {
    width: 22px;
  }

  .inner-wrapper {
    padding: 20px 0px;

    h1 {
      color: #112d53;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 24px;
    }

    h4 {
      color: #80848e;
      font-weight: 400;
      font-size: 16px;
    }

    p {
      color: #1c3359;
      font-size: 16px;
      font-weight: 600;
    }

    .view-map {
      color: $primary-color;
      font-size: 16px;
      font-weight: 600;
      text-decoration: underline;
    }
  }
}

.member-list {
  h4 {
    color: #11224d;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .samaj-list-details {
    width: 60% !important;
  }

  .member-age {
    width: 20%;
    margin-left: auto;
  }
}

// =====================================END HERE================================

.btn-filter {
  color: $primary-color;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 15px;
  border: 0;
  -webkit-box-shadow: 10px 10px 5px 14px rgba(222, 222, 222, 0.74);
  -moz-box-shadow: 10px 10px 5px 14px rgba(222, 222, 222, 0.74);
  box-shadow: 10px 10px 5px 14px rgba(222, 222, 222, 0.74);

  span {
    margin-left: 10px;
  }
}

.member-wrapper {
  padding-bottom: 70px;
}

.member-detail-wrapper {
  h2 {
    font-size: 18px;
    color: #11224d;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
  }

  .form-group {
    p {
      color: #11224d;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.left-border {
  border-left: 1px solid #ddd;
}

// ======================================FILTERS===============================

.filters-wrapper {
  margin-bottom: 0;
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  transform: translateY(100%);
  transition: 0.5s all;

  .filter-top-bar {
    background-color: $primary-color;
    padding: 15px 15px;

    h2 {
      font-size: 20px;
      color: #fff;
      font-weight: 600;
      margin-bottom: 0;
      display: inline-block;
      vertical-align: middle;
    }

    h3 {
      color: #fff;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0;
      margin-top: 11px;
    }

    .close-filter {
      color: #fff;
      font-size: 34px;
      margin-right: 10px;
    }
  }

  .apply-btn {
    color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $primary-color;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    padding: 15px 15px;
    border: 0;
  }
}

// ========================================= REACT TABS ======================================

.react-tabs {
  display: flex;
  width: 100%;
  border: 1px solid #3c3e43;
  color: white;
  background: #1c1d1f;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 0;
  padding: 0;
  color: white;
  background: #3c3e43;
  height: calc(100vh - 124px);
  overflow: scroll;
}

.react-tabs__tab {
  height: 70px;
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #bbb;
}

.react-tabs__tab-panel {
  display: none;
  width: 60%;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
}

.panel-content {
  text-align: center;
}

// ========================================= END HERE ==========================================

.react-tabs__tab-list {
  background: #ebf0f3;
}

.react-tabs__tab p {
  margin-bottom: 0;
}

.react-tabs__tab p span {
  color: $primary-color;
}

.react-tabs__tab {
  color: #7e7e7e;
  height: 50px;
  padding: 0 10px 0px 20px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 600;
}

.react-tabs__tab--selected {
  background-color: #fff;
  border-left: 0 !important;
  color: #282828;
  border: 0 !important;
  outline: none !important;
}

.react-tabs {
  background-color: #fff;
  border: 0;
  color: #282828;
}

.wrapping-tabs {
  height: calc(100vh - 124px);
}

.react-tabs {
  height: 100%;
}

.age-wrapper {
  display: flex;
  width: 100%;
  align-items: center;

  .select-wrap {
    width: 40%;
  }

  .to-wrap {
    width: 20%;
    color: #747474;
  }

  .form-control {
    padding: 0 10px;
    text-align: left;
  }

  .select-wrap .caretDown {
    right: 5px;
  }

  .ant-select-selection-item {
    height: 36px;
    line-height: 34px !important;
  }
}

.checkbox-wrap {
  text-align: left;

  ul {
    margin-bottom: 0;
    padding-bottom: 0;

    li {
      margin-bottom: 12px;
      font-size: 16px;

      label span {
        font-size: 16px;
      }
    }
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
}

.panel-content {
  padding: 15px 15px;
}

.react-tabs__tab-panel {
  height: calc(100vh - 124px);
  overflow: scroll;
}

.ant-checkbox .ant-checkbox-inner:after {
  left: 5px;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: $primary-color;
  border-color: $primary-color;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color;
  border-color: $primary-color;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: $primary-color;
}

.filter-active {
  transform: translateY(0%);
  transition: 0.5s all;
}

.ant-select-selector:focus {
  box-shadow: none !important;
  outline: none !important;
}

.ant-select-selector:active {
  box-shadow: none !important;
  outline: none !important;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
  .ant-select-selector {
  box-shadow: none !important;
}

// ===========================================CHOGHADIYA=========================================

.choghadiya-wrapper {
  background-color: #fff;

  .top-red-bar {
    background-color: $primary-color;
    padding: 25px 0;
    text-align: center;
    position: relative;
    z-index: 999;

    h1 {
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0px;

      img {
        position: absolute;
        left: 15px;
        width: 22px;
        top: 3px;
      }
    }
  }

  .inner-wrapper {
    padding: 0px 0;

    .container {
      padding: 0;
    }
  }

  .time-wrapper {
    padding-left: 15px;
    -webkit-box-shadow: 5px -10px 15px 0px rgba(222, 222, 222, 0.74);
    -moz-box-shadow: 5px -10px 15px 0px rgba(222, 222, 222, 0.74);
    box-shadow: 5px -10px 15px 0px rgba(222, 222, 222, 0.74);
    padding-top: 20px;
    min-height: calc(100vh - 130px);

    h5 {
      font-size: 15px;
      margin-bottom: 0;
      margin-top: 15px;
    }

    .col-6 {
      height: 60px;
    }
  }
}

.slide-wrapper {
  h6 {
    color: #9d9d9d;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 2px;
  }
}

.right-wrapper-scroll {
  width: auto;
  overflow: scroll;
}

.days-wrapper {
  margin-bottom: 20px;

  ul {
    margin-bottom: 0;
    display: flex;
    width: 700px;
    padding: 20px 0px 20px 0px;
    -webkit-box-shadow: -10px 3px 20px 0px rgba(222, 222, 222, 0.74);
    -moz-box-shadow: -10px 3px 20px 0px rgba(222, 222, 222, 0.74);
    box-shadow: -10px 3px 20px 0px rgba(222, 222, 222, 0.74);
    height: 60px;
    background-color: #fff;
    position: relative;
    z-index: 99;

    li {
      color: #050505;
      font-size: 16px;
      font-weight: 700;
      width: 100px;
      text-align: center;
    }
  }
}

.content-choghadiya {
  margin-bottom: 0;
  display: flex;
  width: 700px;

  li {
    font-size: 16px;
    color: #050505;
    font-weight: 700;
    width: 100px;
    text-align: center;
    height: 60px;
  }
}

.from-to-wrap {
  padding: 20px 0px 20px 0px;
  -webkit-box-shadow: -10px 3px 20px 0px rgba(222, 222, 222, 0.74);
  -moz-box-shadow: -10px 3px 20px 0px rgba(222, 222, 222, 0.74);
  box-shadow: -10px 3px 20px 0px rgba(222, 222, 222, 0.74);
  height: 60px;
  background-color: #fff;
  position: relative;
  z-index: 99;
}

.padding-0 {
  padding: 0 !important;
}

// ==============================================END HERE========================================

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  width: 100%;

  button {
    color: $primary-color;
    text-transform: uppercase;
    text-align: center;
    margin-top: 5px;
    font-size: 13px;
    background-color: transparent;
    padding: 0;
    font-weight: 500;
    margin-top: 0;
  }
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 115px;
  margin: 0px auto;
  right: 0;
}

@media only screen and (min-width: 550px) {
  .choghadiya-wrapper .container {
    padding: 0;
  }

  .darshan-detail-wrapper .slick-slide img {
    height: 500px;
    object-fit: cover;
  }

  .profile-wrapper label {
    font-size: 15px;
  }

  .profile-wrapper .form-control {
    font-size: 16px;
  }

  .ant-select-selection-item {
    font-size: 16px;
  }

  .filters-wrapper .react-tabs__tab-list {
    width: 30%;
  }

  .filters-wrapper .react-tabs__tab-panel {
    width: 70%;
  }
}

@media only screen and (min-width: 580px) {
  .choghadiya-wrapper .inner-wrapper .container {
    padding: 0;
    max-width: 100%;
  }
}

// ===========================================LOADER=========================================

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 18px;
}

.lds-ellipsis div {
  position: absolute;
  top: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  transform: translateY(-50%);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

// ==============================================END HERE========================================

.load-more {
  color: $primary-color;
  border: 0;
  padding: 0;
  margin: 20px 0;
  background-color: transparent;
}

.err-msg {
  color: $primary-color;
  font-size: 14px;
  margin-top: 4px;
}

.commonPage-wrapper {
  h1 {
    font-size: 100px;
    color: $primary-color;
    font-weight: 700;
    line-height: initial;
  }
  a {
    background-color: #29a744;
    padding: 15px 25px;
    color: #fff;
    font-weight: 700;
    margin-top: 25px;
    display: inline-block;
    border-radius: 5px;
  }

  h4 {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: "Raleway", sans-serif;
    color: $primary-color;
  }

  .mid-content-wrap {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    color: #9d9d9d;
  }
}

.comingsoon-wrapper {
  h1 {
    font-size: 40px;
    color: $primary-color;
    font-weight: 700;
    line-height: initial;
  }
  a {
    background-color: #29a744;
    padding: 15px 25px;
    color: #fff;
    font-weight: 700;
    margin-top: 25px;
    display: inline-block;
    border-radius: 5px;
  }

  .mid-content-wrap {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h4 {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: $font-secondary;
    color: $primary-color;
  }

  p {
    color: #9d9d9d;
  }
}

.coming-sson-img {
  width: 250px;
  margin: 0px auto;
}

.news-event-wrapper {
  .list-block {
    .samaj-list-details {
      p {
        color: #2886c7;
        font-size: 13px;
        font-weight: 700;
        margin-bottom: 0;
      }
    }
  }
}

.news-event-detail {
  .top-red-bar {
    background-color: $primary-color;
    padding: 25px 0;
    text-align: center;
    h1 {
      position: relative;
      text-align: center;
      color: #fff;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 0px;
    }
    img {
      position: absolute;
      left: 15px;
      width: 22px;
      top: 3px;
    }
  }
  .inner-wrapper {
    .date-event {
      color: #2886c7;
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 0;
      margin-top: 5px;
    }
  }
}

.news-event-wrapper {
  .event-thumbnail {
    .icon-font-calender {
      font-size: 30px;
      color: $primary-color;
      margin-right: 10px;
    }
  }
}

.samaj-c-wrapper {
  min-height: 100vh;
}

.cal-icon {
  font-size: 50px;
  color: $primary-color;
}

.filter-token {
  padding: 0;
  margin: 0;
  margin-top: 20px;

  li {
    display: inline-block;
    margin-right: 5px;
    background-color: #ddd;
    border-radius: 20px;
    padding: 5px 15px;
    vertical-align: middle;
    font-size: 13px;
    margin-bottom: 8px;
  }
}

// ======================= LOADING CSS========================

.loader-3 {
  width: 48px;
  height: 48px;
  border: 3px solid #ddd;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

.loader-3:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #ff3d00;
}

.loading-wrapper {
  background-color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn:focus {
  box-shadow: none !important;
}

.hint-color {
  color: #686d79;
}

// -------------------------------------------- close for filter --------------------------------------

#close {
  vertical-align: middle;
  display: inline-block;
  background: transparent;
}

#close:hover {
  color: red;
}

// =============================================== REGISTER ===========================================

.register-wrapper {
  background-color: #e5ecf2;
  position: relative;
  padding: 20px 12px;

  h1 {
    font-weight: 300;
    color: #282828;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 26px;
  }

  p {
    color: #282828;
    font-size: 16px;
  }

  h3 {
    color: #282828;
    font-size: 18px;
    display: block;
    text-align: left;
    width: 100%;
  }

  .form-control {
    height: 50px;
  }

  .register-img {
    width: 90px;
  }

  .hr-register {
    margin-top: 25px;
  }

  label {
    font-size: 13px;
    color: #282828;
  }

  .regi-footer-btn {
    button {
      font-size: 13px;
      width: 99% !important;
      background-color: #29a744;
      padding: 13px 0px;
      color: #fff;
      font-weight: 400;
      border: 0;
    }
  }

  .card-wrapper {
    background-color: #fff;
    border-radius: 6px;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px 15px 20px 15px;
    -webkit-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
    -moz-box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
    box-shadow: 10px 10px 5px -3px rgba(222, 222, 222, 0.74);
    position: relative;
  }

  .select-wrap .caretDown {
    right: 5px;
  }

  .ai-close-card {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 22px;
  }
}

.back-to-home-btn {
  color: #d3d2d2;
  margin-top: 20px;
  display: inline-block;

  .home-react-icon {
    font-size: 22px;
    margin-right: 5px;
    position: relative;
    top: -3px;
  }
}

.back-to-home-btn:hover {
  color: #fff;
}

// ============================================ error message =================================================

.error-msg {
  color: white;
}

.notification-box {
  text-align: left;

  h3 {
    font-size: 18px;
    text-align: left;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0;
    color: #fff;
    background-color: #0e7dc4;
  }

  h4 {
    font-size: 14px;
    color: #d22c2e;
    margin-bottom: 20px;
  }

  .inner-notification-wrap {
    padding: 15px 15px;
    height: 260px;
    overflow: auto;
  }

  ul {
    li {
      display: flex !important;
      margin-bottom: 15px;
      color: #000;
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: 15px;
      font-size: 16px;
      font-weight: 600;
      .birthday-icon {
        display: inline-block;
        margin-right: 12px;
        color: #d22c2e;
        font-size: 20px;
        position: relative;
        top: -2px;
      }
    }
  }
}

.new-achievement-list .samaj-list-details {
  width: 80% !important;
}

.profile-circle-wrap {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0px auto;
  overflow: hidden;
  margin-bottom: 30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.event-thumbnail {
  position: relative;

  .event-date-cal {
    position: absolute;
    width: 80%;
    background-color: #fff;
    margin: 0px auto;
    height: 60%;
    top: 11px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .date-number {
    font-size: 18px;
    color: #d22c2e;
    font-weight: 600;
    display: block;
    line-height: 22px;
  }

  .month-section {
    font-size: 9px;
    position: relative;
    top: -10px;
    color: red;
  }
}

.cross-relative {
  position: relative;
  top: -3px;
}

.detail-subject {
  h6 {
    text-align: left !important;
  }
}
